import Api from "../../Api";
import loginStateStore from "../../login_state_store";
import pubsubStore from "../../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";
  const moduleClassName = ".topic-plan-to-read-button-module";

  let getReadStateText = (readState) => {
    if (readState == "plan_to_read") {
      return "読みたい";
    }
    if (readState == "on_hold") {
      return "積読";
    }
    if (readState == "reading") {
      return "読んでる";
    }
    if (readState == "completed") {
      return "読んだ";
    }

    return '読みたい';
  }

  let isButtonEnabled = (readStatus) => {
    if (readStatus == 'nothing' || readStatus == 'plan_to_read') {
      return true;
    }
    return false
  }

  let isPlanToRead = (readStatus) => {
    if (readStatus == 'plan_to_read') {
      return true;
    }
    return false
  }

  let setPlanToReadButtonStatus = (element, readState) => {
    let readStateText = getReadStateText(readState);

    let actionButtonElement = element.querySelector(".action-button");
    let actionButtonIconElement = element.querySelector(".icon .material-icons-outlined");
    let actionButtonTextElement = element.querySelector(".text");

    actionButtonTextElement.innerText = readStateText

    if (isButtonEnabled(readState) == false) {
      // 使用不可
      actionButtonElement.classList.add("disabled");
      return;
    }

    if (isPlanToRead(readState)) {
      // 選択状態
      actionButtonElement.classList.add("selected");
      actionButtonIconElement.textContent = "bookmark_added";
    } else {
      // 未選択
      actionButtonElement.classList.remove("selected");
      actionButtonIconElement.textContent = "bookmark_add";
    }
  }

  let initPlanToReadState = element => {
    let planToReadButtonElement = element.querySelector(".topic-plan-to-read-button");
    let topicId = planToReadButtonElement.dataset.topicId;
    let boardId = planToReadButtonElement.dataset.boardId;

    let actionButtonElement = element.querySelector(".action-button");
    let actionButtonIconElement = element.querySelector(".icon .material-icons-outlined");
    let actionButtonTextElement = element.querySelector(".text");

    if (loginStateStore.isLoggedIn() == false) {
      planToReadButtonElement.addEventListener("click", function () {
        const uri = encodeURI(location.pathname + location.search + location.hash);
        location.href = '/login?rt=' + uri;
      });
      return;
    }

    Api.boardReadStatus(boardId).then(json => {
      let readStatus = json.read_status;

      setPlanToReadButtonStatus(element, readStatus)

      if (readStatus == 'nothing' || readStatus == 'plan_to_read') {
        planToReadButtonElement.addEventListener("click", function () {
          if (actionButtonElement.classList.contains("selected") == true) {
            actionButtonElement.classList.remove("selected");
            actionButtonIconElement.textContent = "bookmark_add";
            const nothing_id = 0;

            setPlanToRead(boardId, topicId, nothing_id)
            updateState(document.querySelectorAll(moduleClassName), 'nothing', boardId)
          } else {
            actionButtonElement.classList.add("selected");
            actionButtonIconElement.textContent = "bookmark_added";

            const plan_to_read_id = 0;

            setPlanToRead(boardId, topicId, 1)
            updateState(document.querySelectorAll(moduleClassName), 'plan_to_read', boardId)
          }
        });
      }
    });
  };

  let setPlanToRead = (boardId, topicId, readStatus) => {
    if (topicId == undefined) {
      Api.updateReadStatus(boardId, readStatus)
    } else {
      Api.updateReadStatusPlanToReadFromTopic(boardId, topicId);
    }
  }

  let initState = modules => {
    modules.forEach(element => {
      initPlanToReadState(element)
    });
  };

  let updateState = (modules, readStatus, targetBoardId) => {
    modules.forEach(element => {
      let planToReadButtonElement = element.querySelector(".topic-plan-to-read-button");
      let boardId = planToReadButtonElement.dataset.boardId;
      if (targetBoardId === boardId) {
        setPlanToReadButtonStatus(element, readStatus)
      }
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initState(document.querySelectorAll(moduleClassName));
  });

  pubsubStore.subscribe("renderAsyncLoad", eventContainer => {
    initState(eventContainer.querySelectorAll(moduleClassName));
  });
});
