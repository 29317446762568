import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";
  const moduleClassName = ".board-read-status-button-module";

  let getReadStateText = (readState) => {
    if (readState == "plan_to_read") {
      return "読みたい";
    }
    if (readState == "on_hold") {
      return "積読";
    }
    if (readState == "reading") {
      return "読んでる";
    }
    if (readState == "completed") {
      return "読んだ";
    }

    return '本棚に追加';
  }

  let isIconShow = (readState) => {
    if (readState == 'nothing') {
      return true;
    }
    return false

  }

  let setReadStatusActionButtonStatus = (element, readState) => {
    let readStateText = getReadStateText(readState);
    let actionButtonElement = element.querySelector(".action-button");
    let actionButtonElementText = actionButtonElement.querySelector(".text");
    let actionButtonElementIcon = actionButtonElement.querySelector(".icon");

    actionButtonElementText.innerText = readStateText
    if (isIconShow(readState)) {
      actionButtonElement.classList.remove("selected");
      actionButtonElementIcon.style.display = 'display'
    } else {
      actionButtonElement.classList.add("selected");
      actionButtonElementIcon.style.display = 'none'
    }
  }

  let initReadStatusState = (element) => {
    let readStatusButtonElement = element.querySelector(".board-read-status-button");
    let boardId = readStatusButtonElement.dataset.boardId;
    let readStatusActionsElement = element.querySelector(".read-status-actions");
    let readStatusActionButtonElement = element.querySelector(".read-status-action-button");

    readStatusButtonElement.addEventListener("click", function () {
      if (loginStateStore.isLoggedIn() == false) {
        const uri = encodeURI(location.pathname + location.search + location.hash);
        location.href = '/login?rt=' + uri;
        return;
      } else {
        if (readStatusActionsElement.classList.contains("open") == true) {
          readStatusActionsElement.classList.remove("open");
        } else {
          readStatusActionsElement.classList.add("open");
        }
      }
    });

    readStatusActionsElement.querySelectorAll('.action-button').forEach(readStatusActionElement => {
      readStatusActionElement.addEventListener("click", function () {
        event.stopPropagation();

        let readStatus = readStatusActionElement.dataset.readStatus;
        let readStatusName = readStatusActionElement.dataset.readStatusName;
        setReadStatusActionButtonStatus(readStatusActionButtonElement, readStatusName)
        Api.updateReadStatus(boardId, readStatus);

        if (readStatusName == "completed" || readStatusName == "reading") {
          let lightReviewModules = document.querySelectorAll(".light-review-dialog-module");

          if (lightReviewModules.length == 0) {
            return;
          }

          lightReviewModules.forEach(lightReviewModule => {
            const lightReviewBoardId = lightReviewModule.dataset.boardId;
            if (boardId == lightReviewBoardId) {
              const dialogElement = lightReviewModule.querySelector(".light-review-dialog");
              dialogElement.showModal();
            }
          });
        }
      });
    });

  };

  let updateReadStatusState = element => {
    let readStatusButtonElement = element.querySelector(".board-read-status-button");
    let readStatusActionButtonElement = element.querySelector(".read-status-action-button");
    let boardId = readStatusButtonElement.dataset.boardId;

    if (loginStateStore.isLoggedIn() == false) {
      return;
    }

    Api.boardReadStatus(boardId).then(json => {
      let readStatus = json.read_status;
      setReadStatusActionButtonStatus(readStatusActionButtonElement, readStatus)
    });
  };

  let initState = modules => {
    modules.forEach(element => {
      initReadStatusState(element)
      updateReadStatusState(element)
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initState(document.querySelectorAll(moduleClassName));
  });

  pubsubStore.subscribe("infiniteScrollPageAppend", appendRootElement => {
    initState(appendRootElement.querySelectorAll(moduleClassName));
  });

  pubsubStore.subscribe("renderAsyncLoad", eventContainer => {
    initState(eventContainer.querySelectorAll(moduleClassName));
  });

});
